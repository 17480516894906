<div *ngIf="data && (qmi$ | async) as qmi" class="QmiSummary text-center fixed-bottom d-flex justify-content-around"
  [@fadeInUpOnEnter]>
  <div class="d-flex justify-content-center ml-5">
    <div class="col-2 d-flex justify-content-center align-items-center flex-column mr-3">
      <div [ngClass]="qmi.Plan.PlanName?.length > 12 ? 'QmiSummary__small-plan-name': 'QmiSummary__planName'">
        {{qmi.PlanName}}
      </div>
      <div *ngIf="qmi.Plan && qmi.Plan.SeriesName" class="QmiSummary__label">{{data.Series_Name | mergeField:
        '{series}':qmi.Plan.SeriesName}}</div>
    </div>
    <div class="col-auto mr-4 QmiSummary__address p-0 d-flex justify-content-center align-items-center flex-column">
      <div>{{qmi.Address.Street1}}</div>
      <div>Lot {{qmi.LotBlock}}</div>
    </div>
    <div class="d-flex align-items-center ml-3"
      [ngClass]="qmi.CallForPricingFlag ? 'justify-content-left' : 'justify-content-center'">
      <div class="col-auto mx-4">
        <div class="QmiSummary__value">{{qmi.DateAvailable}}</div>
        <div class="QmiSummary__label">{{data.Availability}}</div>
      </div>
      <ngContainer
        [ngTemplateOutput]="qmi.LmpConfiguration.Enable_LMP && !qmi.CallForPricingFlag ? lowestMonthlyPrice : totalPrice">
        <ngTemplate #lowestMonthlyPrice>
          <div ngIf="qmi.MortgagePayment?.TotalMonthlyPrincipalAndInterest && qmi.DisplayPIOnly; else PITIContent">
            <div class="col-auto mx-4">
              <div class="QmiSummary__value">{{qmi.MortgagePayment.TotalMonthlyPrincipalAndInterest | currency:
                'USD':'symbol':'1.0-0'}} /mo
                <app-lmp-tooltip [data]="lmpConfiguration" [mortgagePayment]="plan.MortgagePayment"></app-lmp-tooltip>
              </div>
              <div class="QmiSummary__label">{{data.LMPStartingFrom}}</div>
            </div>
          </div>
          <ngTemplate #PITIContent>
            <div ngIf="qmi.MortgagePayment?.TotalMonthlyPayment && !qmi.DisplayPIOnly">
              <div class="col-auto mx-4">
                <div class="QmiSummary__value">{{qmi.MortgagePayment.TotalMonthlyPayment | currency:
                  'USD':'symbol':'1.0-0'}} /mo
                  <app-lmp-tooltip [data]="lmpConfiguration" [mortgagePayment]="plan.MortgagePayment"></app-lmp-tooltip>
                </div>
                <div class="QmiSummary__label">{{data.PitiPriceLabel}}</div>
              </div>
            </div>
          </ngTemplate>
        </ngTemplate>
        <ngTemplate #totalPrice>
          <div class="col-auto mx-4" [ngClass]="{'col-2' : qmi.CallForPricingFlag}">
            <div *ngIf="!qmi.CallForPricingFlag">
              <div class="QmiSummary__value">{{(qmi.PriceDiscount ? qmi.FinalPrice : qmi.Price) | currency:
                'USD':'symbol':'1.0-0'}}</div>
              <div class="QmiSummary__label">{{data.Price}}</div>
            </div>
            <div *ngIf="qmi.CallForPricingFlag" class="QmiSummary__label">
              <h6>{{data.Call_For_Pricing_Text}}</h6>
            </div>
          </div>
        </ngTemplate>
      </ngContainer>
      <div class="col-auto mx-4">
        <div class="QmiSummary__value">{{qmi.Bedrooms}}</div>
        <div class="QmiSummary__label">{{data.Bedrooms}}</div>
      </div>
      <div class="col-auto mx-4">
        <div class="QmiSummary__value">{{qmi.TotalBaths}}</div>
        <div class="QmiSummary__label">{{data.Bathrooms}}</div>
      </div>
      <div class="col-auto mx-4">
        <div class="QmiSummary__value">{{qmi.SquareFeet}}</div>
        <div class="QmiSummary__label">{{data.Square_Feet}}</div>
      </div>
      <div class="col-auto mx-4">
        <div class="QmiSummary__value">{{qmi.Garages}}</div>
        <div class="QmiSummary__label">{{data.Garage}}</div>
      </div>
    </div>
  </div>
  <app-request-brochure-cta [ctaData]="data" [currentQmiData]="qmi" [@fadeInOnEnter]></app-request-brochure-cta>
  <div class="col-auto QmiSummary__back pl-3">
    <span class="QmiSummary__back-button" (click)='backButton()'><app-back-button></app-back-button></span>
  </div>
</div>